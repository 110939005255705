
.container-formulario{
    display:flex;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}

.txt-tittle{
    padding: 12px 0;
    color: #19364a;
    font-size: 22px;
    font-weight: 800;

}

.container-side-logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #07c8fe;
  color: #19364a;

}

.Containder-logo{
    background-color: #07c8fe;
    width: 80%;

}

.formulario{
   width: 50%;
   text-align: center;
}

.input-error {
    border: 2px solid red;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
   

.container-person{
    width: 90%;
    margin: 0 auto;
}

.container-person p {
  color: #19364a;
  font-size: 16px;
  font-weight: 800;
  padding-bottom: 15px;
  margin-bottom: 35px;
}

.container-person p{
    text-align: left;
}

.container-person P {
    margin-top: 10px;
    margin-bottom: -1px;
    font-weight: bolder;
}

.input-texto{
    width: 100%;
    border: 3px solid #07c8fe;
    padding: 10px 0 10px 10px;
    border-radius: 10px;
}
.container-mail-phone{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px ;
}

.input-texto-esencial{
    width: 100%;
    border: 3px solid #07c8fe;
    padding: 10px 0 10px 10px;
    border-radius: 10px;
    margin-right: 0px;
}

.input-texto-esencial-select{
    width: 100%;
    border: 3px solid #07c8fe;
    padding: 10px 0;
    border-radius: 10px;
}

.input-adicional{
    width: 100%;
    height: 20vh;
    border: 3px solid #07c8fe;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

.btn-enviar-form2 {
    background-color: #000;
    font-size: 20px;
    color: white;
    border: none;
    padding: 10px 60px;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    z-index: 1;
  }
  
  .btn-enviar-form2:hover {
    background-color: #07c8fe;
    color: #000;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .btn-enviar-form2:active {
    background-color: #05a7cb;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
    transition: background-color 0.1s ease, box-shadow 0.1s ease;
  }
  
  .btn-enviar-form2.spinner {
    display: none;
    position: absolute;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #007bff;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  .btn-enviar-form2.sending {
    background-color: #6c757d;
  }
  
  .btn-enviar-form2.sending .button-text {
    display: none;
  }
  
  .btn-enviar-form2.sending .spinner {
    display: block;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  @media (max-width: 1800px) {
    .container-formulario{
      display:flex;
      width: 100%;
      height: 100vh;
      min-height: 100vh;
    }
  }


  @media (max-width: 1280px) {
    .container-formulario{
      display:flex;
      width: 100%;
      height: 120vh;
      min-height: 100vh;
    }
  }


@media (max-width: 600px) {

    .container-formulario{
        display:flex;
        flex-direction: column;
        width: 100%;
        height: 10vh;
    }
    
    .Containder-logo{
        width: 100%;
    }

    .input-texto-esencial{
        margin-right: 0;
    }
    .input-texto-esencial-select{
        margin-right: 0;
    }
    .formulario{
        width: 98%;
        text-align: center;
     }

     .container-person{
        width: 90%;
        margin: 0 auto;
    }

    .input-adicional{
        width: 95%;
        height: 20vh;
        border: 2px solid #07c8fe;
        padding-top: 10;
        padding-left: 10px;
        border-radius: 10px;
    }
    
     

  }
  
  @media (max-width: 900px) {
    .container-formulario{
        display:flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
    }

    .Containder-logo{
        background-color: #07c8fe;
        width: 100%;
    
    } 
    .formulario{
       width: 100%;
       text-align: center;
    }
  }


  @media (max-width: 1024px) {
    .container-formulario{
      display:flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      min-height: 100vh;
  }

  .Containder-logo{
      background-color: #07c8fe;
      width: 100%;
  
  } 
  .formulario{
     width: 100%;
     text-align: center;
  }
  }