.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    font-size: 30px;
    z-index: 1000;
    text-decoration: none;
    transition: transform 0.3s ease-in-out;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
  }
  