.movilContainer {
  position: relative;
  height: 90vh;
}

.imagenMobile {
  height: 90vh;
  object-fit: cover;
}

.position-absolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoPadding {
  padding: 80px;
}

.style-pack {
  color: #fff;
  font-size: 24px;
}

#estilo-lema {
  background-color: #fff;
  color: #000;
  width: 70%;
  margin: 0 auto;
  opacity: 70%;
  border-radius: 10px;
}

@media (max-width: 992px) {
  .movilContainer {
    position: relative;
    height: 30vh;
  }
  
  .imagenMobile {
    height: 30vh;
    object-fit: cover;
  }
  .logoPadding {
    padding: 60px;
  }
  .style-pack {
    font-size: 20px;
  }
  #estilo-lema {
    width: 80%;
    opacity: 100%;
  }
}

@media (max-width: 768px) {
  .logoPadding {
    padding: 40px;
  }
  .style-pack {
    font-size: 18px;
  }
  #estilo-lema {
    width: 90%;
  }
}


@media (max-width: 576px) {
  .logoPadding {
    padding: 0px;
  }
  .style-pack {
    font-size: 16px;
  }
  #estilo-lema {
    width: 95%;
  }
}
