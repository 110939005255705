

#estilo-lema{
  color: "#13103A" !important;;
  background-Color: "#fff" !important;;
  font-Weight: "bold" !important;;
  width: "70%";
  margin: "0 auto";
  border-Radius: "10px";
  opacity: "60%"
}

.img-carousel{
  height: 100%;

}


.img-container {
  cursor: pointer;
  transition: transform 0.3s;
}

.img-container:hover {
  transform: scale(1.2);
}

.textoBanner {
  margin-right: 0%;
  justify-content: start;

}

.span-text {
  font-weight: bolder;
  color: #e5943a;
  font-size: 35px;

}

.margenSVG {
  margin-bottom: -10rem;
}

.imagenBanner {
  margin-left: 40px;
}

.infoCountries {
  width: 100%;
  height: 100% !important;
  background-size: cover;
}

.imagenMex {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/zocalo2-mexico%202%20(1).png?alt=media&token=1b98dce6-c963-47b0-8a14-88109895b28f');
}

.imagenGuat {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/AntiguaGuatemala%202%20(1).png?alt=media&token=48461338-f03b-4d9f-ac1e-0f2ef448956b');
}

.imagenSv {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/Monumento_al_Salvador_del_Mundo_1%201%20(1).png?alt=media&token=60d1ae20-75e8-4ff8-abcd-267f53b1317e');
}

.imagenHond {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/roatan2-honduras%201.png?alt=media&token=2d546cbc-7667-4ae5-9a18-a3edb2530086');
}

.imagenNic {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/69293-Leon%202.png?alt=media&token=4a75f25c-5c3c-4b28-93a0-6ad840a2ccf6');
}

.imagenColm {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/cartagena%201.png?alt=media&token=b576d871-77c0-4aa1-b7d9-edc1f7359a30');
}

.imagenCosta {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/wp1879064-costa-rica-wallpapers%201.png?alt=media&token=180fa482-e178-48a3-9c7a-dac04d45f385');
}

.imagenVenez {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/Uo2ifkX-venezuela-wallpaper%201.png?alt=media&token=7610566a-6be9-44c8-9ada-40c60a3e4425');
}

.imagenRep {
  transition: background-image 0.5s ease-in-out;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/cargo-pre.appspot.com/o/Dominican-Republic-travel-guide-Travel-S-helper%201.png?alt=media&token=922a27fb-62ca-4b74-973a-a45c184199f5');
}

.circulo {
  background-color: white;
  border-radius: 50%;
  width: 500px;
  height: 500px;
  margin: 20px;
}

.country {
  padding-left: 30%;
  width: 70%;
}

.textoRep {
  font-size: 25px;
  padding-top: 40px;
}

.logoPadding {
  padding: 8%;
}

#estilo-lema{
  color: "#13103A" !important;;
  background-Color: "#fff" !important;;
  font-Weight: "bold" !important;;
  width: "70%";
  margin: "0 auto";
  border-Radius: "10px";
  opacity: "60%"
}

.img-carousel{
  height: 100%;

}

/* Estilos para dispositivos móviles pequeños */
@media (max-width: 414px) {
  .font-class{
    font-size: 30px !important;
  }
  .circulo {
    background-color: white;
    border-radius: 50%;
    width: 100% !important;
    height: 45vh;
    margin: 0;
  }

  .infoCountries {
    width: 100%;
    height: 65vh;
  }

  .textoRep {
    padding-top: 30px;
    font-size: 20px;
  }

  .margenSVG {
    margin-bottom: 0rem;
  }

  .textoMovil {
    font-size: calc(1.275rem + -1vw) !important;
  }

  .logoPadding {
    padding: 0%;
  }

  .imagenMobile {
    height: 25vh;
  }
}


@media (max-width: 667px) {
  .img-container {
    transition: transform 0.3s;
  }

  .imagen {
    width: 100px;
  }

  .img-container:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .textoBanner, .imagenBanner {
    margin: 0;
  }

  circulo {
    background-color: white;
    border-radius: 50%;
    width: 100% !important;
    height: 45vh;
    margin: 0;
  }

  .country {
    padding-left: 30%;
    width: 70%;
  }

  .infoCountries {
    height: 65vh !important;
  }

  .buttonSizes {
    --bs-btn-padding-y: 0.1rem !important;
    --bs-btn-padding-x: 0.8rem !important;
    width: 40vh;
    margin-top: 25px;
  }

  .logoMision, .logoVision {
    display: none;
  }

  .txt-Vision, .txt-Mision {
    text-align: center !important;
  }

  .descript {
    padding: 0px 10px !important;
  }

  .margenSVG {
    margin-bottom: 0rem;
  }

  .textoMovil {
    font-size: calc(1.275rem + -1vw) !important;
  }

  .logoPadding {
    padding: 0%;
  }

  .imagenMobile {
    height: 25vh;
  }
}

/* Estilos para dispositivos móviles medianos */
@media (max-width: 320px) and (max-height: 568px) {

  .font-class{
    font-size: 30px;
  }

  .circulo {
    background-color: white;
    border-radius: 50%;
    width: 100% !important;
    height: 58vh;
    margin: 0;
    margin-top: -10%;
  }

  .infoCountries {
    height: 75vh !important;
  }

  .textoRep {
    padding-top: 30px;
    font-size: 20px;
  }

  .margenSVG {
    margin-bottom: 0rem;
  }

  .textoMovil {
    font-size: calc(1.275rem + -2vw) !important;
  }

  .logoPadding {
    padding: 0%;
  }

  .imagenMobile {
    height: 25vh;
  }
  .btn-lg{
    font-size: 1px;
  }
  .buttonSizes {
    --bs-btn-padding-y: 0.1rem !important;
    --bs-btn-padding-x: 0.8rem !important;
    width: 45vh;
    margin-top: 20px;
    --bs-btn-font-size: 1rem !important;
  }
}

/* Estilos para tablets */
@media (min-width: 668px) and (max-width: 1024px) {
  .img-container {
    transition: transform 0.3s;
  }

  .imagen {
    width: 150px;
  }

  .img-container:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .textoBanner, .imagenBanner {
    margin: 0;
  }

  .circulo {
    width: 50vh !important;
    height: 50vh;
    margin: 10px auto;
  }

  .country {
    padding-left: 20%;
    width: 80%;
  }

  .infoCountries {
    height: 70vh !important;
  }

  .buttonSizes {
    --bs-btn-padding-y: 0.1rem !important;
    --bs-btn-padding-x: 0.8rem !important;
    width: 35vh;
    margin-top: 20px;
  }

  .logoMision, .logoVision {
    display: none;
  }

  .txt-Vision, .txt-Mision {
    text-align: center !important;
  }

  .descript {
    padding: 0px 10px !important;
  }

  .margenSVG {
    margin-bottom: 0rem;
  }

  .textoMovil {
    font-size: calc(2.275rem + -1vw) !important
  }

  .logoPadding {
    padding: 0%;
  }

  .imagenMobile {
    height: 30vh;
  }
}